import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import graphImg from "../assets/svg/graph-img.svg";
import SideLine from "./sideLine";

const style = {
  card: {
    border: "#30363c",
    padding: "0",
  },
  cardHeader: {
    borderBottom: "1px solid #30363c",
    searchInput: {
      border: "1px solid #30363c",
      backgroundColor: "#0d1116",
      borderRadius: "8px",
    },
  },
  cardBody: {
    padding: "0",
  },
  cardSideBar: {
    // border: "#30363c",
    border:
      document.documentElement.dir === "rtl"
        ? "1px solid #30363c"
        : "1px solid #30363c",
    border:
      document.documentElement.dir === "ltr"
        ? "1px solid #30363c"
        : "1px solid #30363c",
    padding: "0",
  },
  cardEndBar: {
    // border: "#30363c",
    border:
      document.documentElement.dir === "rtl"
        ? "1px solid #30363c"
        : "1px solid #30363c",
    border:
      document.documentElement.dir === "ltr"
        ? "1px solid #30363c"
        : "1px solid #30363c",
    padding: "0",
  },
  view: {
    text: {
      label: {
        minWidth: "120px",
      },
    },
  },
};

function ScreenMock(props) {
  const { t } = useTranslation();

  const [view, setView] = useState("graph");

  const itemData = [
    {
      type: "title",
      label: t("screen-title"),
      value: t("screen-title-value"),
    },
    {
      type: "entityType",
      label: t("screen-entityType"),
      value: t("screen-entityType-value"),
    },
    {
      type: "bookSize",
      label: t("screen-bookSize"),
      value: t("screen-bookSize-value"),
    },
    {
      type: "station",
      label: t("screen-station"),
      value: t("screen-station-value"),
    },
    {
      type: "name",
      label: t("screen-name"),
      value: t("screen-name-value"),
    },
    {
      type: "type",
      label: t("screen-type"),
      value: t("screen-type-value"),
    },
    {
      type: "originOfProduction",
      label: t("screen-originOfProduction"),
      value: t("screen-originOfProduction-value"),
    },
    {
      type: "publisher",
      label: t("screen-publisher"),
      value: t("screen-publisher-value"),
    },
    {
      type: "publishDate",
      label: t("screen-publishDate"),
      value: t("screen-publishDate-value"),
    },
    {
      type: "shabak",
      label: t("screen-shabak"),
      value: t("screen-shabak-value"),
    },
    {
      type: "pageNumber",
      label: t("screen-pageNumber"),
      value: t("screen-pageNumber-value"),
    },
  ];

  const toViewGraph = () => {
    console.log("toViewGraph");
    setView("graph");
  };

  const toViewText = () => {
    console.log("toViewText");
    setView("text");
  };

  const newTabArray = [
    {
      label: "KDR",
      value: "tab 1",
    },
    // {
    //   label: "Result page | Libra KDR",
    //   value: "tab 2",
    // },
    // {
    //   label: "Refrence page | Libra KDR",
    //   value: "tab 3",
    // },
  ];

  const [tabValue, setTabValue] = useState("tab 1");

  const toNewTab = (value) => {
    console.log("value__", value);
    setTabValue(value);
  };

  const tabContent = () => {
    for (let i = 0; i < newTabArray.length; i++) {
      // const element = array[i];
      if (tabValue == "tab " + (i + 1)) {
        return "tab " + (i + 1) + " content";
        // console.log('content__', 'tab ' + i + 'content')
      }
    }
  };

  const graphViewContent = (
    <div>
      <div className="row p-4">
        <div
          class="btn-group col-12 col-md-8"
          role="group"
          aria-label="Basic radio toggle button group"
          style={{ maxWidth: "420px" }}
        >
          <button
            className={`btn ${
              view !== "text" ? "btn-primary" : "btn-outline-primary"
            } ${
              document.documentElement.dir === "rtl"
                ? "rounded-0 rounded-end"
                : ""
            }`}
            onClick={toViewGraph}
          >
            {t("screen-switcher-btn-graph")}
          </button>
          <button
            className={`btn ${
              view !== "graph" ? "btn-primary" : "btn-outline-primary"
            } ${
              document.documentElement.dir === "rtl"
                ? "rounded-0 rounded-start"
                : ""
            }`}
            onClick={toViewText}
          >
            {t("screen-switcher-btn-text")}
          </button>
        </div>
        <div className="btn-group col-6"></div>
      </div>
      <div className="row p-4">
        {view !== "graph" ? (
          <div className="col-12">
            {/* <h1>Text View</h1> */}
            {itemData.map((item, index) => (
              <div className="d-flex">
                <p style={style.view.text.label}>{item.label}</p>
                <p className="mx-2">:</p>
                <span>{item.value}</span>
              </div>
            ))}
          </div>
        ) : (
          <div className="col-10">
            {/* <h1>Graph View</h1> */}
            <img src={graphImg} rel="graph" />
          </div>
        )}
      </div>
    </div>
  );

  const resultPageKDR = (
    <img
      className="w-100 my-3"
      src={
        localStorage.getItem("lang") === "fa"
          ? require(`../assets/png/md-result-red-fa.png`)
          : require(`../assets/png/md-result-red-en.png`)
      }
      alt={""}
    />
  );

  return (
    <div className="container text-light">
      <div className="row" style={{ height: "100px" }}>
        <SideLine
          iconClassName={"bi-code"}
          //   label={true}
          height={"h-100"}
          position={"vertical-line-gradient-s"}
        />
      </div>
      <div className="row">
        <div className="card" style={style.card}>
          <div class="card-header" style={style.cardHeader}>
            <div className="d-flex w-100 mb-4">
              {newTabArray.map((item, index) => (
                <button
                  key={index}
                  className="btn btn-sm btn-dark d-flex border-bottom-0"
                  style={{
                    backgroundColor: item.value == tabValue ? "#161b21" : "",
                    borderRadius: "8px 8px  0 0",
                  }}
                  onClick={() => toNewTab(item.value)}
                >
                  <div className="d-flex w-100 justify-content-between">
                    <p className="fw-light my-1">{item.label}</p>
                    <div className="mt-1 mx-2">
                      <i class="bi bi-x"></i>
                    </div>
                  </div>
                </button>
              ))}
            </div>
            <div className="d-flex justify-content-between px-2">
              <div className="d-flex mt-1">
                <div
                  className="d-flex justify-content-between"
                  style={{ minWidth: "80px" }}
                >
                  <i
                    id="back"
                    class={`bi ${
                      document.documentElement.dir === "rtl"
                        ? "bi-chevron-right"
                        : "bi-chevron-left"
                    }`}
                    style={{ fontSize: "1.25rem" }}
                  ></i>
                  <i
                    id="front"
                    class={`bi ${
                      document.documentElement.dir === "ltr"
                        ? "bi-chevron-right"
                        : "bi-chevron-left"
                    }`}
                    style={{ fontSize: "1.25rem" }}
                  ></i>
                </div>
              </div>
              <div className="d-flex w-100 mx-3 d-none d-md-block">
                <div className="py-1 px-2" style={style.cardHeader.searchInput}>
                  <span>KDR</span>
                </div>
              </div>
              <div className="d-flex  mt-1">
                <i class="bi bi-plus-lg" style={{ fontSize: "1.25rem" }}></i>
              </div>
            </div>
          </div>
          <div class="card-body" style={style.cardBody}>
            <div className="row w-100 m-0">
              {/* <div className={`col-4 ${document.documentElement.dir === "ltr" ? 'border-end' : 'border-start'}`} style={style.cardSideBar}> */}
              <div className="col-12 col-sm-3" style={style.cardSideBar}>
                <div className="row p-4">
                  <h3>{props.title}</h3>
                  <p>{props.desc}</p>
                </div>
              </div>
              <div className="col-12 col-sm-9">
                <div className="row h-100">
                  <div className="col-11">
                    {/* this should be un comment when u wanna have tabbars */}
                    {/* {tabContent()} */}
                    {props.view == "result-page"
                      ? resultPageKDR
                      : graphViewContent}
                  </div>
                  <div className="col-1" style={style.cardEndBar}>
                    <div className="d-flex justify-content-center mt-4 h-100">
                      <i class="bi bi-list" style={{ fontSize: "1.5rem" }}></i>
                    </div>
                  </div>
                </div>
                {/* {props.view == "result-page" ? resultPageKDR : graphViewContent} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row" style={{ height: "100px" }}>
        <SideLine
          iconClassName={"bi-code"}
          //   label={true}
          height={"h-100"}
          position={"vertical-line-gradient-e"}
        />
      </div>
    </div>
  );
}

export default ScreenMock;

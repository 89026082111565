import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Slide } from "react-slideshow-image";
import axios from "axios";

import Books from "../components/books";

import AboutUs from "../components/aboutUs";
import Welcome from "../components/welcome";
import ContactUs from "../components/contactUs";
import KDR from "./kdr";
import Book from "../components/book";
import KdrCycle from "../components/kdrCycle";

const slideImages = [
  {
    caption: "Slide 1",
  },
  {
    caption: "Slide 2",
  },
  {
    caption: "Slide 3",
  },
];

function Home() {
  const { t } = useTranslation();

  const [data, setData] = useState("");

  useEffect(() => {
    axios
      .get("http://localhost:3000/books")
      .then((response) => {
        // handle success
        console.log(response);
        setData(response.data);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .finally((response) => {
        // always executed
      });
  }, []);

  return (
    <div className="margin-from-top" style={{marginTop: '6rem'}}>
      {/* <KdrCycle /> */}
      <Welcome />
      <KDR />
      {/* <Books /> */}
      {/* <Book /> */}
      <AboutUs />
      <ContactUs />
    </div>
  );
}

export default Home;

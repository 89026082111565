import React, { useEffect, useState } from "react";
import { HashLink as Link } from "react-router-hash-link";

import { useTranslation } from "react-i18next";

// import KDR_LogoAnimated from "../assets/svg/logo-animated.svg";
import KDR_Logo from "../assets/svg/dpkdr-logo.svg";
import KDR_LogoType from "../assets/svg/dpkdr-logo.svg";

function Navbar() {
  const { t, i18n } = useTranslation();

  const navItems = [
    {
      name: t("nav-item-product-and-services"),
      link: "#product-and-services",
    },
    {
      name: t("nav-item-about-us"),
      link: "#about-us",
    },
    {
      name: t("nav-item-contact-us"),
      link: "#contact-us",
    },
  ];

  const [ScroolY, setScroolY] = useState("");

  window.addEventListener("scroll", () => {
    setScroolY(window.scrollY);
  });

  const rtlLanguages = ["fa"];

  function setPageDirection(language) {
    const dir = rtlLanguages.includes(language) ? "rtl" : "ltr";
    document.documentElement.dir = dir;
  }

  const changeLang = () => {
    if (i18n.language === "en") {
      i18n.changeLanguage("fa");
      setPageDirection("fa");
      localStorage.setItem("lang", "fa");
    } else {
      i18n.changeLanguage("en");
      setPageDirection("en");
      localStorage.setItem("lang", "en");
    }
  };

  useEffect(() => {
    document.documentElement.dir =
      localStorage.getItem("lang") === "fa" ? "rtl" : "ltr";
    document.body.classList = document.documentElement.dir || "ltr";
  }, [document.documentElement.dir]);

  return (
    <>
      <nav
        id="navbar"
        className={`navbar fixed-top navbar-expand-lg navbar-dark bg-dark show-nav ${
          ScroolY >= 10 ? "" : "navbar-transparent"
        } `}
      >
        <div className="container">
          <Link
            className="navbar-brand d-md-block d-lg-none logo-width"
            to={`/`}
          >
            <img className="w-75" src={KDR_Logo} alt={""} />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 col col-xs-12 col-sm-12 col-md-12 col-lg-4 d-flex justify-content-start">
              {navItems.map((item) => (
                <li key={item.link} className="nav-item">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to={`/${item.link}`}
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 col col-xs-12 col-sm-12 col-md-12 col-lg-4 d-none d-sm-none d-md-none d-lg-block">
              <li className="nav-item d-flex justify-content-center">
                <Link
                  className="navbar-brand d-flex align-middle justify-content-center m-auto"
                  aria-current="page"
                  to={`/`}
                >
                  {/* <img
                    className="logo-animated"
                    src={KDR_LogoAnimated}
                    alt={""}
                  />
                  <div className="my-1 mx-3 logo-right-border"></div> */}
                  <img className="logo-type w-75" src={KDR_LogoType} alt={""} />
                </Link>
              </li>
            </ul>
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 col col-xs-12 col-sm-12 col-md-12 col-lg-4 d-flex justify-content-end">
              <div className="nav-item">
                <button
                  className="btn btn-outline-primary"
                  onClick={changeLang}
                >
                  {i18n.language === "en"
                    ? t("nav-item-persian")
                    : t("nav-item-english")}
                </button>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;

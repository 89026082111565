import React from "react";
import { useTranslation } from "react-i18next";
import CountUp from "react-countup";
// import HoverImage from "react-hover-image";

import SideLine from "./sideLine";
import SegmentHeader from "./segmentHeader";

const publishers = [
  { name: "masjed-azam", url: "https://ils.blib.ir/" },
  { name: "allame-tabatabaei", url: "https://atu.libramee.com/" },
  { name: "bonyad-saadi", url: "https://pltdl.saadifoundation.ir/" },
  { name: "majma-ahlebeyt", url: "https://abknowledge.net/" },
  
];

function AboutUs() {
  const { t } = useTranslation();

  function backgroundImageWelcome() {
    let elem = document.getElementById("bg-img-03");
    let scrollY = window.scrollY / 100;
    elem.style.backgroundSize = 65 + scrollY + "%";
  }
  window.addEventListener("scroll", backgroundImageWelcome);

  return (
    <>
      <section id="about-us">
        <div className="container d-flex align-items-center reveal">
          <div className="row">
            <div className="col-lg-1 d-none d-xs-none d-sm-none d-md-none d-lg-block">
              <SideLine
                iconClassName={"bi-people-fill"}
                label={true}
                height={"h-100vh"}
                position={"vertical-line-gradient-s"}
              />
            </div>
            <div className="col col-12 col-xs-12 col-sm-12 col-md-12 col-lg-11">
              <div className="row align-items-center h-100">
                <div className="col col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4">
                  <SegmentHeader
                    title={t("about-us-title")}
                    desc={t("about-us-desc")}
                  />

                  <div className="card p-4 mt-5 text-light">
                    <div className="row h-100" role="alert">
                      <div className="col-8">
                        <span>{t("about-us-users-number")}</span>
                      </div>
                      <div className="col-4">
                        <h4 className="mt-3">
                          <CountUp
                            duration={3}
                            enableScrollSpy={true}
                            end={2302}
                          />
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col col-12 col-xs-12 col-sm-12 col-md-8 col-lg-8 reveal">
                  <section
                    id="bg-img-03"
                    className="position-absolute w-100 section-100 mid-image-background"
                  ></section>
                  <div className="">
                    {publishers.map((item, index) => (
                      <a href={item.url} key={index}>
                        <img
                          src={require(`../assets/svg/brands/${item.name}.svg`)}
                          className="m-3 mx-5"
                          style={{ width: "140px" }}
                        />
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutUs;

import { useTranslation } from "react-i18next";
import ScreenMock from "../components/screenMock";
import SideBar from "../components/sideBar";

import { JsonLd } from "react-schemaorg";

const keyFeaturesList = [
  "kdr-features-list-1",
  "kdr-features-list-2",
  "kdr-features-list-3",
  "kdr-features-list-4",
  "kdr-features-list-5",
  "kdr-features-list-6",
  "kdr-features-list-7",
  "kdr-features-list-8",
];

const systemModulesList = [
  { a: "kdr-system-modules-list-1", b: "kdr-system-modules-list-1b" },
  { a: "kdr-system-modules-list-2", b: "kdr-system-modules-list-2b" },
  { a: "kdr-system-modules-list-3", b: "kdr-system-modules-list-3b" },
  { a: "kdr-system-modules-list-4", b: "kdr-system-modules-list-4b" },
  { a: "kdr-system-modules-list-5", b: "kdr-system-modules-list-5b" },
  { a: "kdr-system-modules-list-6", b: "kdr-system-modules-list-6b" },
  { a: "kdr-system-modules-list-7", b: "kdr-system-modules-list-7b" },
  { a: "kdr-system-modules-list-8", b: "kdr-system-modules-list-8b" },
  { a: "kdr-system-modules-list-9", b: "kdr-system-modules-list-9b" },
  { a: "kdr-system-modules-list-10", b: "kdr-system-modules-list-10b" },
];

const standardsList = [
  { a: "kdr-Standards-list-1", b: "" },
  { a: "kdr-Standards-list-2", b: "" },
  { a: "kdr-Standards-list-3", b: "" },
  { a: "kdr-Standards-list-4", b: "" },
  {
    a: "kdr-Standards-list-5",
    b: ["kdr-Standards-list-1b", "kdr-Standards-list-2b"],
  },
  {
    a: "kdr-Standards-list-6",
    b: "",
  },
  { a: "kdr-Standards-list-7", b: "" },
  { a: "kdr-Standards-list-8", b: "" },
  { a: "kdr-Standards-list-9", b: "" },
  { a: "kdr-Standards-list-10", b: "" },
];

const kdr_services = [
  "kdr-introduction-services-list-1",
  "kdr-introduction-services-list-2",
  "kdr-introduction-services-list-3",
  "kdr-introduction-services-list-4",
];

export default function KDR() {
  const { t } = useTranslation();

  return (
    <>
      <div className="text-light">
        <div className="container">
          <div className="row">
            <div className="col-12 d-none d-md-block">
              <SideBar link={"https://www.yecomsoft.com/kdr-brochure.pdf"} />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <h1>{t("kdr-introduction-title")}</h1>
              <p>{t("kdr-introduction-desc")}</p>
            </div>
          </div>

          <div className="row reveal">
            <div className="col-12">
              <h6>{t("kdr-introduction-services-title")}</h6>
              <ul>
                {kdr_services.map((item, index) => (
                  <li key={index}>
                    {t(item)}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="row">
            <ScreenMock
              view={"result-page"}
              title={t("screen-mock-result-page-title")}
              desc={t("screen-mock-result-page-desc")}
            />
          </div>

          <div className="row reveal">
            <div className="col-12">
              <h3>{t("kdr-features-title")}</h3>
              <ul>
                {keyFeaturesList.map((item, index) => (
                  <li key={index}>{t(item)}</li>
                ))}
              </ul>
            </div>
          </div>

          <div className="row reveal">
            <div className="col-12">
              <h3>{t("kdr-Standards-title")}</h3>
              <ul>
                {standardsList.map((item, index) => (
                  <li key={index}>
                    {t(item.a)}
                    {item.b === "" ? (
                      ""
                    ) : (
                      <ul>
                        <li>{t(item.b[0])}</li>
                        <li>{t(item.b[1])}</li>
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="row reveal">
            <div className="col-12">
              <ScreenMock
                view={"graph-view"}
                title={t("screen-mock-graph-view-title")}
                desc={t("screen-mock-graph-view-desc")}
              />
            </div>
          </div>

          <div className="row reveal">
            <div className="col-12">
              <h3>{t("kdr-system-modules-title")}</h3>
              <ul>
                {systemModulesList.map((item, index) => (
                  <li key={index}>
                    {t(item.a)}
                    <ul>
                      <li>{t(item.b)}</li>
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <JsonLd
        item={{
          "@context": "https://schema.org",
          "@type": "Organization",
          "@id":"https://kdrs.io/#organization",
          "logo": [
            "https://kdrs.io/static/media/dpkdr-logo.8c0d4ea47db69bb42160b54eaa89eb94.svg",
           ],
           "image": "https://kdrs.io/static/media/dpkdr-logo.8c0d4ea47db69bb42160b54eaa89eb94.svg",
          "description":"knowledge discovery and representation system (KDR) is a comprehensive and integrated management system of all kinds of information resources with a context-oriented approach and based on a modular architecture model (modularity), with the aim of meeting the various needs of all kinds of libraries, information centers and others.",
          "sameAs": [
            "exlibrisgroup.com"
          ],
          "name": "KDR",
          "review": {
            "@type": "Review",
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "5",
              "bestRating": "5"
            },
            "author": {
              "@type": "Person",
              "name": "Sepehr Rokni"
            }
          },
          "url": "https://www.kdrs.io",
          "email": "info@kdrs.io",
        }}
      />
      <JsonLd
        item={{
          "@context": "https://schema.org",
          "@type": "Product",
          description:
            "knowledge discovery and representation system (KDR) is a comprehensive and integrated management system of all kinds of information resources with a context-oriented approach and based on a modular architecture model (modularity), with the aim of meeting the various needs of all kinds of libraries, information centers and others.",
          name: "KDR",
          keywords: "بهترین نرم افزار کتابخانه ای, بهترین نرم افزار کتابداری, نرم افزار کتابخانه دیجیتال, مدیریت منابع دیجیتال, مدیریت منابع موزه ای, شی موزه ای, روابط بین اشیا, روابط بین منابع, سازماندهی, مدیریت امانت, امانت دیجیتالی, نرم افزار پژوهش کده, کتابخانه ی عمومی, کتابخانه ی دانشگاه, پژوهشگاه, استاندارد بیب فریم, استاندارد Bibframe, استاندارد ال آر ام, استاندارد LRM, استاندارد آر دی ای, استاندارد RDA, مراکز اسناد, نرم افزار مرکز اسناد, نرم افزار كتابخانه, پايگاه دانش, نرم افزار مديريت كتابخانه, نرم افزار مديريت پژوهشگاه, نرم افزار مديريت پژوهشكده, مديريت دانش, شرکت داده پردازي کشف و بازنمود دانش",
          image: 
            "https://kdrs.io/static/media/dpkdr-logo.8c0d4ea47db69bb42160b54eaa89eb94.svg",
          aggregateRating: {
            "@type": "AggregateRating",
            ratingValue: "5.0",
            reviewCount: "10",
          },
          review: [
            {
              "@type": "Review",
              author: {
                "@type": "Person",
                name: "Sepehr Rokni",
              },
              datePublished: "2022-04-01",
              reviewBody:
                "The KDR is a wonderful product that make for a great jobs",
              reviewRating: {
                "@type": "Rating",
                bestRating: "5",
                ratingValue: "1",
                worstRating: "1",
              },
            },
            {
              "@type": "Review",
              author: {
                "@type": "Person",
                name: "Arman Kalhori",
              },
              datePublished: "2022-04-15",
              reviewBody:
                "Great library system management for the price. It is useful and best.",
              name: "Value purchase",
              reviewRating: {
                "@type": "Rating",
                bestRating: "5",
                ratingValue: "4",
                worstRating: "1",
              },
            },
          ],
        }}
      />
    </>
  );
}

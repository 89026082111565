import React from "react";
import { useTranslation } from "react-i18next";

import SideLine from "./sideLine";
import SegmentHeader from "./segmentHeader";

function ContactUs() {
  const { t } = useTranslation();

  return (
    <>
      <section id="contact-us">
        <div className="container d-flex align-items-center reveal">
          <div className="row">
            <div className="col-lg-1 d-none d-xs-none d-sm-none d-md-none d-lg-block">
              <SideLine
                iconClassName={"bi-send"}
                label={true}
                height={"h-100vh"}
              />
            </div>
            <div className="col col-12 col-xs-12 col-sm-12 col-md-12 col-lg-11">
              <div className="row align-items-center h-100">
                <div className="col col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4">
                  <SegmentHeader
                    title={t("contact-us-title")}
                    desc={t("contact-us-desc")}
                  />
                  {/* <div className="mt-5" role="alert">
                    <div className="my-4">
                      <p>{t("contact-us-form-label-tel")}</p>
                      <a className="d-flex" href="tel:+982166957360">
                        <i className="bi bi-chat-text"></i>
                        <h4 className="mx-2" style={{direction: 'ltr'}}>(021) 6695 7360</h4>
                      </a>
                    </div>
                    <div className="my-4">
                      <p>{t("contact-us-form-label-email")}</p>
                      <a className="d-flex" href="mailto:info@kdrs.io">
                        <i className="bi bi-envelope"></i>
                        <h4 className="h6 mx-2">info@kdrs.io</h4>
                      </a>
                    </div>
                  </div> */}
                </div>
                <div className="col col-12 col-xs-12 col-sm-12 col-md-12 col-lg-8 card card-body reveal">

                    <div className="px-5">
                      <div className="my-4">
                        <p>{t("contact-us-form-label-tel")}</p>
                        <a className="d-flex" href="tel:+982166957360">
                          <i className="bi bi-chat-text"></i>
                          <h4 className="mx-2" style={{ direction: "ltr" }}>
                            (021) 6695 7360
                          </h4>
                        </a>
                      </div>
                      <div className="my-4">
                        <p>{t("contact-us-form-label-email")}</p>
                        <a className="d-flex" href="mailto:info@kdrs.io">
                          <i className="bi bi-envelope"></i>
                          <h4 className="h6 mx-2">info@kdrs.io</h4>
                        </a>
                      </div>
                    </div>
                    {/* <div
                    className="card bg-dark p-4"
                    //   style="border: 1px solid #525252;"
                  >
                    <div className="row my-4">
                      <div className="col">
                        <input
                          id="firstname"
                          type="text"
                          className="form-control"
                          placeholder={t("contact-us-form-first-name")}
                          aria-label={t("contact-us-form-first-name")}
                        />
                      </div>
                      <div className="col">
                        <input
                          id="lastname"
                          type="text"
                          className="form-control"
                          placeholder={t("contact-us-form-last-name")}
                          aria-label={t("contact-us-form-last-name")}
                        />
                      </div>
                    </div>
                    <div className="row my-4">
                      <div className="col">
                        <input
                          id="email"
                          type="email"
                          className="form-control"
                          placeholder={t("contact-us-form-email")}
                          aria-label="Email"
                        />
                      </div>
                    </div>
                    <div className="row my-4">
                      <div className="col">
                        <textarea
                          id="message"
                          style={{ fontWeight: "500" }}
                          className="form-control"
                          rows="3"
                          placeholder={t("contact-us-form-message")}
                        ></textarea>
                      </div>
                    </div>
                    <button className="btn btn-primary my-4 border-0">
                      {t("contact-us-form-submit")}
                    </button>
                  </div> */}

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactUs;
